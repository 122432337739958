/* eslint-disable no-restricted-globals */

import { precacheAndRoute } from "workbox-precaching";

// This will be replaced with the actual precache manifest during the build process
precacheAndRoute(self.__WB_MANIFEST || []);

const CACHE_NAME = "ogascounty-cache-v1";
const urlsToCache = [
  "/",
  "/index.html",
  "/static/css/main.css",
  "/static/css/main.8798880c.css",
  "/static/js/bundle.js",
  "/static/js/main.dfd24776.js",
  "/static/js/462.9eb6e33d.chunk.js",
  "/static/js/237.e746f333.chunk.js",
  "/static/js/257.10f45625.chunk.js",
  "/static/js/453.2d40735f.chunk.js",
  // Add other assets you want to cache here
];

// Install event - Cache assets
self.addEventListener("install", (event) => {
  event.waitUntil(
    caches.open(CACHE_NAME).then((cache) => {
      console.log("Opened cache");
      return cache.addAll(urlsToCache);
    })
  );
});

// Activate event - Clean up old caches
self.addEventListener("activate", (event) => {
  const cacheWhitelist = [CACHE_NAME];
  event.waitUntil(
    caches.keys().then((cacheNames) =>
      Promise.all(
        cacheNames.map((cacheName) => {
          if (!cacheWhitelist.includes(cacheName)) {
            return caches.delete(cacheName);
          }
        })
      )
    )
  );
});

// Fetch event - Serve cached content when offline
self.addEventListener("fetch", (event) => {
  event.respondWith(
    caches.match(event.request).then((response) => {
      // If we have a cached response, return it
      if (response) {
        return response;
      }

      // Clone the request to ensure it's not consumed before the cache put operation
      const fetchRequest = event.request.clone();

      // Fetch the request and handle response caching
      return fetch(fetchRequest).then((response) => {
        // Check if we received a valid response
        if (
          !response ||
          response.status !== 200 ||
          response.type !== "basic" ||
          fetchRequest.url.startsWith("chrome-extension://")
        ) {
          return response;
        }

        // Clone the response to put it in cache
        const responseToCache = response.clone();

        caches.open(CACHE_NAME).then((cache) => {
          cache.put(event.request, responseToCache);
        });

        return response;
      });
    })
  );
});
